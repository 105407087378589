import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Img from 'gatsby-image'

import breakpoints from 'helpers/breakpoints'
import theme from 'constants/theme'

import Section from 'components/atoms/layout/Section'
import Heading from 'components/atoms/headings/Heading'
import Box from 'components/atoms/Box'
import TeamMember from 'components/molecules/cards/TeamMember'
import TextPlx from 'components/atoms/plx/TextPlx'

const ClearfixContainer = styled(Container)`
  &:after {
    display: block;
    content: '';
    clear: both;
  }

  .team-member-card {
    width: 85%;
    margin-top: 50px;

    ${breakpoints.greaterThan('sm')`
      width: 65%;
    `}
    ${breakpoints.greaterThan('md')`
      width: 45%;
    `}

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }
  }

  ${breakpoints.greaterThan('lg')`
    .text-box {
      max-width: 41.666667%;
      float: right;
    }

    .team-member-card {
      width: 41.666667%;

      &:not(.first) {
        margin-top: 150px;
      }

      &.left {
        float: left;
        margin-left: 5%;

        &.item-2 {
          margin-left: 7%;
        }        
      }

      &.right {
        float: right;
        margin-right: 5%;

        &.item-1 {
          margin-right: 7%;
        }         
      }


      &.item-3 {
        margin-top: 200px;
      }  
    }    
    `}

  ${breakpoints.greaterThan('xl')`

    .team-member-card {
      width: 32%;

      &.left {
        margin-left: 5%;

        &.item-2 {
          margin-left: 13%;
        }        
      }

      &.right {
        margin-right: 5%;

        &.item-1 {
          margin-right: 10%;
        }         
      }


      &.item-3 {
        margin-top: 200px;
      }  
    }    


  `}
`

const Team = ({ data, openSwiper }) => {
  let sectionPadding = theme.sectionPadding
  sectionPadding.xl = '200px'

  return (
    <Section bg="greyLight" pt={sectionPadding} pb={{ xs: 10, md: 13, lg: 15 }}>
      <ClearfixContainer maxWidth="xl" className="foreground">
        <Box className="text-box" p={{ lg: '15px' }}>
          <TextPlx>
            <Heading $as="h2" headingSize="h2" mb="3">
              {data.teamSectionHeading}
            </Heading>
          </TextPlx>
          <TextPlx>{data.teamSectionText}</TextPlx>
        </Box>

        {data.teamSectionMembers.map((member, index) => {
          const isEven = index % 2 > 0

          return (
            <TeamMember
              key={member.originalId}
              member={member}
              outerPadding={{ xs: 0, lg: '15px' }}
              openSwiper={openSwiper}
              index={index}
              className={`team-member-card item-${index} ${
                index === 0 ? 'first' : ''
              } ${isEven ? 'right' : 'left'}`}
            />
          )
        })}
      </ClearfixContainer>
    </Section>
  )
}

Team.propTypes = {
  data: PropTypes.object.isRequired,
}

export default memo(Team)
