import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'

import BackgroundImageLogo from 'components/organisms/sections/BackgroundImageLogo'
import Heading from 'components/atoms/headings/Heading'
import SmallHeading from 'components/atoms/headings/SmallHeading'
import Box from 'components/atoms/Box'
import Flex from 'components/atoms/Flex'
import FaderSwiper from 'components/molecules/swipers/FadeSwiper'
import TextPlx from 'components/atoms/plx/TextPlx'

const Quotes = ({ data }) => {
  return (
    <BackgroundImageLogo image={data.quotesSectionBackgroundImage}>
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <FaderSwiper fullHeight>
          {data.teamQuotes.map(item => {
            return (
              <div key={`quote-${item.originalId}`}>
                <Flex center full flexDirection="column" textAlign="center">
                  <TextPlx>
                    <Heading as="p" headingSize="h1" color="white">
                      &quot;{item.quote}&quot;
                    </Heading>
                  </TextPlx>
                  <TextPlx>
                    <SmallHeading
                      color="white"
                      $as="p"
                      headingSize="1.7rem"
                      fontWeight="normal"
                      noDivider
                      mt={{ xs: 4 }}
                    >
                      {item.teamMember.name} - {item.teamMember.jobTitle}
                    </SmallHeading>
                  </TextPlx>
                </Flex>
              </div>
            )
          })}
        </FaderSwiper>
      </Container>
    </BackgroundImageLogo>
  )
}

Quotes.propTypes = {
  data: PropTypes.object.isRequired,
}

export default memo(Quotes)
