import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import Hidden from '@material-ui/core/Hidden'

import breakpoints from 'helpers/breakpoints'
import { useSlidesOverlayContext } from 'context/SlidesOverlayContext'

import ProductSwiper from 'components/molecules/swipers/ProductSwiper'
import Flex from 'components/atoms/Flex'
import Box from 'components/atoms/Box'
import Heading from 'components/atoms/headings/Heading'
import SmallText from 'components/atoms/text/SmallText'
import ExternalButtonLink from 'components/atoms/buttons/ExternalButtonLink'

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    top: 0;
  }
`

const ContainerGrid = styled(Grid)`
  ${breakpoints.greaterThan('lg')`
    align-items: stretch;
    height: 100vh;
    min-height: 1000px;
  `}
  font-size: 1.6rem;

  .content-grid {
    order: 3;
  }
  .image-grid {
    order: 1;
  }

  ${breakpoints.greaterThan('lg')`
    .content-grid {
        order: 1;

    }
    .image-grid {
        order: 3;

    }
  `}
`
const ImageGrid = styled(Grid)`
  background: ${(props) => props.theme.colors.greyLight};
  position: relative;
`

const ImageFlex = styled(Flex)`



  ${breakpoints.lessThan('lg')`
    transform: translateY(50px);
    margin-top: -50px;
  `}
  ${breakpoints.greaterThan('lg')`
    position: absolute !important;
    top: 10%;
    right: 12%;
    bottom: 10%;
    left: 12%;
    width: auto;
    height: auto;    
  `}
  ${breakpoints.greaterThan('xl')`
    top: 14%;
    right: 20%;
    bottom: 14%;
    left: 20%; 
  `}
`

const StyledImage = styled(Img)`
  width: 100%;
  // border: 50px solid white;

  ${breakpoints.greaterThan('lg')`
    position: absolute;
    width: 80%;
    height: 80%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      object-fit: contain !important;
    }
  `}
`

const Name = styled(Heading)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: rotate(-90deg) translate3d(-50%, 0, 0);
  transform-origin: center left;
  line-height: 1;
`

const StyledSmallText = styled(SmallText)`
  border-left: 4px solid ${(props) => props.theme.colors.primary};
  padding-left: 20px;
  display: block;
  width: auto;
  line-height: 1;
`

const TeamDetail = () => {
  const { slides, slidesType, open, startIndex } = useSlidesOverlayContext()
  if (slides && slides.length > 0 && slidesType === 'team') {
    return (
      <StyledDrawer open={open} anchor="bottom">
        <ProductSwiper startIndex={startIndex}>
          {slides.map((member) => {
            const isPortrait =
              member &&
              member.image &&
              member.image.fluid &&
              member.image.fluid.aspectRatio < 1.2
            return (
              <div key={`detail-item-${member.originalId}`}>
                <ContainerGrid container spacing={0}>
                  <Grid item xs={12} lg={6} className="content-grid">
                    <Flex full flexDirection="column">
                      <Box
                        my={'auto'}
                        px={{ lg: 5, xl: 15 }}
                        py={{ xs: 10, lg: '0px' }}
                        textAlign="center"
                      >
                        <Container maxWidth={false}>
                          <Hidden lgUp implementation="css">
                            <Heading $as="h2" headingSize="h3">
                              {member.name}
                            </Heading>
                          </Hidden>

                          <Flex fullWidth center>
                            <StyledSmallText mt={{ xs: 1, lg: 0 }} mb={2}>
                              {member.jobTitle}
                            </StyledSmallText>
                          </Flex>

                          <Box
                            fontSize={{
                              xs: '1.6rem',
                              // lg: '1.7rem',
                              xl: '1.8rem',
                            }}
                            dangerouslySetInnerHTML={{
                              __html: member.aboutNode.childMarkdownRemark.html,
                            }}
                          />
                          {member.instagramLink && (
                            <Flex fullWidth center mt={4}>
                              <ExternalButtonLink
                                className="lg round outlineDark"
                                variant="outlined"
                                href={member.instagramLink}
                                mx={2}
                              >
                                <FontAwesomeIcon icon={faInstagram} />
                              </ExternalButtonLink>
                            </Flex>
                          )}
                        </Container>
                      </Box>
                    </Flex>
                  </Grid>
                  <Hidden mdDown implementation="css">
                    <Box width="0" order={2}>
                      <Name $as="h2" headingSize="h2">
                        {member.name}
                      </Name>
                    </Box>
                  </Hidden>
                  <ImageGrid item xs={12} lg={6} className="image-grid">
                    <Flex
                      px={{ xs: 2, lg: '0px' }}
                      pt={{ xs: 10, lg: '0px' }}
                      pb={{ xs: 0 }}
                    >
                      <ImageFlex
                        full
                        center
                        flexDirection="column"
                        mt={{ xs: 1, lg: '0px' }}
                      >
                        <Flex
                          center
                          flex={{ lg: 1 }}
                          position="relative"
                          width={{
                            xs: '100%',
                            sm: isPortrait ? '40%' : '80%',
                            lg: '100%',
                          }}
                          px={{
                            xs: 2,
                          }}
                          pt={{
                            xs: isPortrait ? 2 : 8,
                            sm: isPortrait ? 4 : 8,
                            lg: '0px',
                          }}
                          pb={{
                            xs: 0,
                          }}
                          mx={{ xs: 2, lg: '0px' }}
                        >
                          <StyledImage fluid={member.image.fluid} />
                        </Flex>
                      </ImageFlex>
                    </Flex>
                  </ImageGrid>
                </ContainerGrid>
              </div>
            )
          })}
        </ProductSwiper>
      </StyledDrawer>
    )
  }
  return null
}

export default TeamDetail
