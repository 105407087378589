import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { withWindowSizeListener } from 'react-window-size-listener'
import Plx from 'react-plx'

import { useSlidesOverlayActionsContext } from 'context/SlidesOverlayContext'

import Box from 'components/atoms/Box'
import Flex from 'components/atoms/Flex'
import Heading from 'components/atoms/headings/Heading'
import SmallText from 'components/atoms/text/SmallText'
import HoverEffect from 'components/atoms/media/HoverEffect'
import { fontFamily } from 'styled-system'

const Container = styled(Box)`
  .hover-text {
    cursor: pointer;
    transition: color ${(props) => props.theme.transitions.long};
  }

  &:hover {
    .hover-text {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`
const Name = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 10%;

  &.bottom {
    top: 100%;
    bottom: auto;
  }
`

const StyledSmallText = styled(SmallText)`
  border-left: 4px solid ${(props) => props.theme.colors.primary};
  padding-left: 20px;
  display: block;
  line-height: 1;
`

const TeamMember = ({ member, outerPadding, index, ...props }) => {
  const { openSlidesOverlay } = useSlidesOverlayActionsContext()

  const isEven = index % 2 == 0 ? true : false

  const parallaxDataImage = [
    {
      start: 'self',
      end: 'self',
      endOffset: '30vh',
      properties: [
        {
          startValue: isEven ? -50 : 50,
          endValue: 0,
          property: 'translateX',
          unit: 'px',
        },
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
  ]
  const parallaxDataText = [
    {
      start: 'self',
      end: 'self',
      endOffset: '35vh',
      properties: [
        {
          startValue: -50,
          endValue: 0,
          property: 'translateX',
          unit: 'px',
        },
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
  ]

  return (
    <Container
      position="relative"
      px={outerPadding}
      mb={3}
      onClick={() => openSlidesOverlay(parseInt(index))}
      {...props}
    >
      <Plx parallaxData={parallaxDataImage}>
        <HoverEffect>
          <Img fluid={member.image.fluid} />
        </HoverEffect>
      </Plx>
      <Name ml={outerPadding} pt={4} px={1} bg="greyLight">
        <Plx parallaxData={parallaxDataText}>
          <Heading
            $as="span"
            headingSize="h4"
            onClick={() => openSlidesOverlay(parseInt(index))}
            className="hover-text"
          >
            {member.name}
          </Heading>
        </Plx>
      </Name>
      <Name
        ml={outerPadding}
        mt={`-${outerPadding}`}
        bg="greyLight"
        className="bottom hover-text"
        px={1}
        pb={1}
        pt={1}
      >
        <Plx parallaxData={parallaxDataText}>
          <StyledSmallText
            mb={0}
            onClick={() => openSlidesOverlay(parseInt(index))}
          >
            {member.jobTitle}
          </StyledSmallText>
        </Plx>
      </Name>
    </Container>
  )
}

TeamMember.propTypes = {
  member: PropTypes.object.isRequired,
}

export default typeof window !== 'undefined'
  ? memo(withWindowSizeListener(TeamMember))
  : TeamMember
