import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Img from 'gatsby-image'
import Hidden from '@material-ui/core/Hidden'

import breakpoints from 'helpers/breakpoints'

import Heading from 'components/atoms/headings/Heading'
import Section from 'components/atoms/layout/Section'
import SmallHeading from 'components/atoms/headings/SmallHeading'
import Box from 'components/atoms/Box'
import TextPlx from 'components/atoms/plx/TextPlx'

const ContainerGrid = styled(Grid)`
  align-items: center;
`
const StyledImg = styled(Img)`
  > div {
    padding-bottom: 85% !important;
  }

  ${breakpoints.greaterThan('md')`


    > div {
      padding-bottom: 150% !important;
    }    
  `}
`

const Story = ({ data }) => {
  return (
    <Section py={{ xs: 4, xl: 10 }}>
      <ContainerGrid container spacing={0}>
        <Grid item xl={1} implementation="css" mdDown component={Hidden} />
        <Grid item xs={12} md={6} xl={5}>
          <Container maxWidth={false}>
            <TextPlx>
              <SmallHeading>{data.storySectionHeading}</SmallHeading>
            </TextPlx>
            <TextPlx>
              <Heading
                as="p"
                headingSize={{
                  xs: '2.5rem',
                  sm: '2.7rem',
                  md: '2.9rem',
                  lg: '3.5rem',
                }}
                lineHeight="1.5"
                fontWeight="medium"
              >
                {data.storySectionText}
              </Heading>
            </TextPlx>
          </Container>
        </Grid>
        <Grid item xl={1} implementation="css" mdDown component={Hidden} />
        <Grid item xs={12} md={6} xl={4}>
          <Container maxWidth={false}>
            <Box mt={{ xs: 4, xl: 0 }} ml={{ xs: '-20px', sm: '-30px', md: 0 }}>
              <StyledImg fluid={data.storySectionImage.fluid} />
            </Box>
          </Container>
        </Grid>
        <Grid item xl={2} implementation="css" mdDown component={Hidden} />
      </ContainerGrid>
    </Section>
  )
}

Story.propTypes = {
  data: PropTypes.object.isRequired,
}

export default memo(Story)
