import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'
import Hidden from '@material-ui/core/Hidden'

import breakpoints from 'helpers/breakpoints'
import theme from 'constants/theme'

import Section from 'components/atoms/layout/Section'
import Heading from 'components/atoms/headings/Heading'
import Box from 'components/atoms/Box'
import MoreButton from 'components/atoms/buttons/MoreButtonLink'
import ScrollRevealText from 'components/atoms/text/ScrollRevealText'
import TextPlx from 'components/atoms/plx/TextPlx'

const ContentGrid = styled(Grid)`
  ${breakpoints.lessThan('md')`
    order: 2;
  `}
`
const StyledImg = styled(Img)`
  > div {
    padding-bottom: 85% !important;
  }

  ${breakpoints.greaterThan('sm')`
    > div {
      padding-bottom: 65% !important;
    }    
  `}

  ${breakpoints.greaterThan('md')`


    > div {
      padding-bottom: 150% !important;
    }    
  `}

  ${breakpoints.greaterThan('lg')`
    transform: translateY(51px);

    > div {
      padding-bottom: 125% !important;
    }    
  `}
`

const Details2 = ({ data }) => {
  let sectionPadding = theme.sectionPadding
  sectionPadding.xl = '200px'

  return (
    <Section
      pt={sectionPadding}
      pb={{ xs: 4, md: 6, lg: 0 }}
      style={{ zIndex: 100 }}
    >
      <Container maxWidth="xl" className="foreground">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={5}>
            <TextPlx reverse>
              <Heading headingSize="h2" mt={{ lg: 10, xl: 12 }} mb={{ xl: 4 }}>
                {data.detailsSection2Heading}
              </Heading>
            </TextPlx>

            <ScrollRevealText
              components={data.detailsSection2TextComponents}
              keyId="about-details2"
              reverse
            />
            <TextPlx reverse>
              <MoreButton
                to={`/${data.detailsSection2ButtonLink.slug}`}
                label={data.detailsSection2ButtonLabel}
              />
            </TextPlx>
          </Grid>
          <Grid item lg={2} implementation="css" mdDown component={Hidden} />
          <Grid item xs={12} md={5}>
            <Box mt={{ xs: 3, lg: 0 }}>
              <StyledImg fluid={data.detailsSection2Image.fluid} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

Details2.propTypes = {
  data: PropTypes.object.isRequired,
}

export default memo(Details2)
