import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import { useSlidesOverlayActionsContext } from 'context/SlidesOverlayContext'

import Layout from 'components/organisms/global/Layout'
import ImageHero from 'components/molecules/hero/ImageHero'
import Heading from 'components/atoms/headings/Heading'
import Story from 'components/organisms/_page-specific/about-us/Story'
import Details1 from 'components/organisms/_page-specific/about-us/Details1'
import Details2 from 'components/organisms/_page-specific/about-us/Details2'
import Quotes from 'components/organisms/_page-specific/about-us/Quotes'
import Team from 'components/organisms/_page-specific/about-us/Team'
import TeamDetail from 'components/organisms/_page-specific/about-us/TeamDetail'

const AboutUs = ({ data }) => {
  const page = data.datoCmsAbout
  const {
    handleSetSlides,
    handleSetSlidesType,
  } = useSlidesOverlayActionsContext()

  useEffect(() => {
    handleSetSlides(page.teamSectionMembers)
    handleSetSlidesType('team')
  }, [])

  const metaObj = {
    title: page.seoMetadata.title,
    description: page.seoMetadata.description,
    image: {
      src: page.metaImage.fixed.src,
      width: page.metaImage.fixed.width,
      height: page.metaImage.fixed.height,
    },
  }

  return (
    <Layout
      metadata={metaObj}
      headerProps={{
        navImage: page.mainNavigationImage,
      }}
    >
      <ImageHero images={page.heroSectionBackgroundImage}>
        <Heading as="h1" headingSize="jumbo" color="white">
          {page.heroSectionHeading}
        </Heading>
      </ImageHero>
      <Story data={page} />
      <Details1 data={page} />
      <Quotes data={page} />
      <Details2 data={page} />
      <Team data={page} />
      <TeamDetail />
    </Layout>
  )
}

AboutUs.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutUs

export const pageQuery = graphql`
  {
    datoCmsAbout {
      seoMetadata {
        description
        title
      }
      metaImage: heroSectionBackgroundImage {
        fixed(
          width: 1024
          height: 512
          imgixParams: {
            fit: "crop"
            w: "1024"
            h: "512"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          width
          height
          src
        }
      }
      detailsSection2ButtonLabel
      detailsSection2ButtonLink {
        ... on DatoCmsCollectionsLanding {
          slug
        }
      }
      detailsSection2Heading
      detailsSection2Image {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      detailsSection2TextComponents {
        ... on DatoCmsParagraph {
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      detailsSectionHeading
      detailsSectionImage {
        fluid(
          maxWidth: 800
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "800"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      detailsSectionTextComponents {
        ... on DatoCmsParagraph {
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      heroSectionBackgroundImage {
        fluid(
          maxWidth: 1400
          imgixParams: { fm: "jpg", auto: "compress" }
        ) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      heroSectionHeading
      mainNavigationImage {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      storySectionHeading
      storySectionImage {
        fluid(
          maxWidth: 600
          imgixParams: { w: "600", fm: "jpg", auto: "compress" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      storySectionText
      quotesSectionBackgroundImage {
        fluid(
          maxWidth: 1400
          imgixParams: { fm: "jpg", auto: "compress" }
        ) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      teamQuotes {
        originalId
        quote
        teamMember {
          name
          jobTitle
        }
      }
      teamSectionHeading
      teamSectionMembers {
        instagramLink
        image {
          fluid(
            maxWidth: 600
            imgixParams: { w: "600", fm: "jpg", auto: "compress" }
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        originalId
        name
        jobTitle
        slug
        aboutNode {
          childMarkdownRemark {
            html
          }
        }
      }
      teamSectionText
    }
  }
`
