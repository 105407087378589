import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Img from 'gatsby-image'
import Hidden from '@material-ui/core/Hidden'

import breakpoints from 'helpers/breakpoints'

import Heading from 'components/atoms/headings/Heading'
import Section from 'components/atoms/layout/Section'
import Box from 'components/atoms/Box'
import ScrollRevealText from 'components/atoms/text/ScrollRevealText'
import TextPlx from 'components/atoms/plx/TextPlx'

const ImageContainer = styled(Container)`
  ${breakpoints.greaterThan('md')`
    padding-left: 0;
  `}
`

const ContainerGrid = styled(Grid)`
  align-items: center;

  ${breakpoints.lessThan('md')`
    .image {
      order: 2;
    }
    .content {
      order: 1;
    } 
  `}
`

const StyledImg = styled(Img)`
  ${breakpoints.between('md', 'lg')`
    > div {
      padding-bottom: 180% !important;
    }    
  `}
  ${breakpoints.between('lg', 'xl')`
    > div {
      padding-bottom: 100% !important;
    }    
  `}
`

const Details1 = ({ data }) => {
  return (
    <Section pb={{ xs: 6, xl: 10 }}>
      <ContainerGrid container spacing={0}>
        <Grid item xs={12} md={6} xl={6} className="image">
          <Box mt={{ xs: 4, xl: 0 }} mr={{ xs: '-20px', sm: '-30px', md: 0 }}>
            <ImageContainer maxWidth={false}>
              <StyledImg fluid={data.detailsSectionImage.fluid} />
            </ImageContainer>
          </Box>
        </Grid>

        <Grid item xl={1} implementation="css" mdDown component={Hidden} />
        <Grid item xs={12} md={6} xl={4} className="content">
          <Container maxWidth={false}>
            <Box pr={{ xl: 4 }}>
              <TextPlx>
                <Heading
                  as="p"
                  headingSize="h2"
                  lineHeight="1.5"
                  fontWeight="medium"
                >
                  {data.detailsSectionHeading}
                </Heading>
              </TextPlx>

              <ScrollRevealText
                components={data.detailsSectionTextComponents}
                keyId="about-details1"
              />
            </Box>
          </Container>
        </Grid>

        <Grid item xl={2} implementation="css" mdDown component={Hidden} />
      </ContainerGrid>
    </Section>
  )
}

Details1.propTypes = {
  data: PropTypes.object.isRequired,
}

export default memo(Details1)
